import LanguageSwitcher from "@jumbo/dz-components/buttons/LanguageSwitcher";
import {
  Button,
  TextField,
  Fab,
  Box,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import {
  List,
  Link,
  ListItem,
  ListItemIcon,
  ListItemText,
  Tab,
  InputAdornment,
} from "@mui/material";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";

import {
  selectedLeadContractSelector,
  setSelectedLeadContract,
  selectedCompanySelector,
  setSelectedCompany,
  selectedLeadSelector,
  selectedLeadOfferSelector,
} from "app/rkt_query/SelectedStuffReducer";
import {
  LANGUAGES,
  selectedLanguageSelector,
  setSelectedLanguage,
} from "app/rkt_query/SettingsReducer";
import {
  BLUE_PRINTS,
  useAddLeadContractMutation,
  useGetLeadContractQuery,
  useUpdateLeadContractMutation,
  useGetAllLeadContractsQuery,
  useGetAllSportsQuery,
  useGetAllCountriesQuery,
  useGetAllCitiesQuery,
  useGetAllLeadContractUsersQuery,
  useGetAllAirportsQuery,
  useGetAllHotelsQuery,
  useGetLeadOfferQuery,
  useGetAllLeadServicesQuery,
  useGetAllLeadContractConditionsQuery,
} from "app/rkt_query/storeApis";
import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import DZDropdown, {
  DZSimpleDropDown,
} from "../../../@jumbo/dz-components/dropdowns/DZDropdown";
import DZTextField, {
  DZDateField,
} from "../../../@jumbo/dz-components/textboxes/DZTextField";
import { useJumboDialog } from "../../../@jumbo/components/JumboDialog/hooks/useJumboDialog";
import Snackbar, {
  ErrorSnackbar,
} from "@jumbo/dz-components/snackbar/Snackbar";
import DZTextValidation, {
  DZTextFieldDisable,
} from "@jumbo/dz-components/textboxes/DZTextValidation";
import SimpleBackdrop from "@jumbo/dz-components/backdrop/BackDrop";
import { Config } from "constant";
import { DZImageCircle } from "@jumbo/dz-components/imageCircle/DZImage";
import {
  addUpdateDivStyleGlobal,
  dialogButtonsStyle,
} from "@jumbo/dz-components/styles/styles";
import Div from "@jumbo/shared/Div";
import Grid from "@mui/material/Grid";
import { Avatar, Card, CardContent, Rating, Typography } from "@mui/material";
import DZLabel, {
  DzNoBackgroundTranslatable,
  DzNoBackgroundTranslatableModule,
} from "@jumbo/dz-components/labels/DZLabel";
import useResponseError from "@jumbo/dz-components/custom-hooks/useResponseError";

import DZDeleteDialog from "@jumbo/dz-components/dialog/DZDeleteDialog";
import DeleteIcon from "@mui/icons-material/Delete";

import ControlPointOutlinedIcon from "@mui/icons-material/ControlPointOutlined";
import DzTextEditorMultilingual from "@jumbo/dz-components/TextEditor/DzTextEditorMultilingual";
import {
  aboutYakSport,
  leadContractDetail,
} from "../lead_offer/InitialDataLeadOffer";
import { useSnackBarManager } from "app/hooks/useSnackBarManager";

const AddUpdateLeadContract = ({ onClose, lead_id }) => {
  const [errors, setErrors] = useState({});
  const [openError, setOpenError] = useState(false);
  const dispatch = useDispatch();

  const selectedLeadContract = useSelector(selectedLeadContractSelector);

  const leadOffer = useSelector(selectedLeadOfferSelector);
  console.log(leadOffer, "redux");
  const { fnShowSnackBar } = useSnackBarManager();

  const selectedLanguage = useSelector(selectedLanguageSelector);
  const lead_contract_status = [
    "draft",
    "send",
    "success",
    "rejected",
    "deleted",
  ];
  const { data: res_get } = useGetLeadContractQuery(selectedLeadContract?.id);
  const { data: res_get_lead_Offer } = useGetLeadOfferQuery(
    selectedLeadContract?.lead_offer_id
  );
  const [updateLeadContract, responseUpdate] = useUpdateLeadContractMutation();
  const [addLeadContract, responseAdd] = useAddLeadContractMutation();
  const { data: res_get_all_lead_services } = useGetAllLeadServicesQuery();
  const { data: res_get_all_lead_contract_condition } =
    useGetAllLeadContractConditionsQuery();
  const bluePrint = BLUE_PRINTS.leadContract; 

  const leadOfferData = res_get_lead_Offer?.data?.lead_offer;

  console.log("lead offer =============================>>>>>>", leadOfferData);
  console.table(leadOfferData);
  console.log("lead offer =============================>>>>>>",leadOfferData);

  const { data: res_get_all_airports } = useGetAllAirportsQuery();
  const { data: res_get_all_hotels } = useGetAllHotelsQuery();
  const [openSnackbar, setOpenSnackbar] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const [leadContractState, setLeadContractState] = useState(null);
  const [showBackDrop, setShowBackDrop] = useState(false);
  const [index1, setIndex] = React.useState(null);
  const [inputFields, setInputFields] = useState([
    { id: "", label: "", price: "" },
  ]);

  const leadData = res_get_all_lead_services?.data?.lead_services ?? [];
  const leadContractConditionData =
    res_get_all_lead_contract_condition?.data?.contract_conditions ?? [];

  const leadContractServiceIds =
    res_get?.data?.lead_contract?.lead_contract_services?.map((leadService) =>
      parseInt(leadService?.lead_service_id, 10)
    );

  const leadContractConditionIds =
    res_get?.data?.lead_contract?.lead_contract_conditions?.map(
      (leadContract) => parseInt(leadContract?.contract_condition_id, 10)
    );

  const handleMileStone1DateChange = (fromValue) => {
    const selectedDate = dayjs(fromValue).format("YYYY-MM-DD");
    setLeadContractState({
      ...leadContractState,
      milestone_date1: selectedDate,
    });
  };

  const handleMileStone2DateChange = (toValue) => {
    const selectedDate = dayjs(toValue).format("YYYY-MM-DD");
    setLeadContractState({
      ...leadContractState,
      milestone_date2: selectedDate,
    });
  };
  const handleExpiredDateChange = (toValue) => {
    const selectedDate = dayjs(toValue).format("YYYY-MM-DD");
    setLeadContractState({
      ...leadContractState,
      expired_at: selectedDate,
    });
  };

  const addUpdateLeadContractData = async (action) => {
    const formData = new FormData();
    formData.append("id", res_get?.data?.lead_contract?.id);
    formData.append(
      "lead_offer_id",
      leadOffer?.id ? leadOffer?.id : selectedLeadContract?.lead_offer_id
    );
    formData.append("expired_at", leadContractState.expired_at);
    formData.append("status", leadContractState.status || "draft");
    formData.append("airline", leadContractState.airline || "");
    formData.append("to_airline", leadContractState.to_airline || "");
    formData.append("milestone_date1", leadContractState.milestone_date1);
    formData.append("milestone_date2", leadContractState.milestone_date2);
    formData.append(
      "is_milestone_date1",
      leadContractState.is_milestone_date1 == 0 ? 0 : 1
    );
    formData.append(
      "is_milestone_date2",
      leadContractState.is_milestone_date2 == 0 ? 0 : 1
    );
    formData.append("flight_time", leadContractState.flight_time);
    formData.append("flight_return_time", leadContractState.flight_return_time);

    //landing time and date
    formData.append("landing_time", leadContractState.landing_time || null);
    formData.append(
      "from_landing_date",
      leadContractState.from_landing_date || null
    );
    formData.append(
      "landing_return_time",
      leadContractState.landing_return_time || null
    );
    formData.append(
      "to_landing_date",
      leadContractState.to_landing_date || null
    );
    //

    formData.append(
      "lead_contract_service_ids",
      JSON.stringify(leadContractState?.lead_contract_service_ids)
    );
    formData.append(
      "lead_contract_condition_ids",
      JSON.stringify(leadContractState?.lead_contract_condition_ids)
    );

    formData.append("detail", JSON.stringify(leadContractState.detail));
    formData.append(
      "milestones_detail",
      JSON.stringify(leadContractState.milestones_detail)
    );

    // return false;
    const res =
      action === "add"
        ? await addLeadContract(formData)
        : await updateLeadContract(formData)
            .unwrap()
            .then((payload) => {
              setInterval(() => {
                window.location.reload();
                dispatch(setSelectedLeadContract(null));
              }, 2000);
            })
            .catch((error) => {});

    if (res?.data?.message) {
      // fnShowSnackBar(res?.data?.message|| "Done successfully");
    } else {
      fnShowSnackBar(res?.error?.data?.message, "error");
    }
  };
  const { error, resetCount } = useResponseError(responseAdd, responseUpdate);
  useEffect(() => {
    setLeadContractState(
      selectedLeadContract
        ? {
            ...res_get?.data?.lead_contract,
            hotel: leadOfferData?.hotel?.name,
            airport: leadOfferData?.airport?.name?.[selectedLanguage],
            from_date: leadOfferData?.from_date,
            to_date: leadOfferData?.to_date,
            flight_time: leadOfferData?.flight_time,
            landing_time: leadOfferData?.landing_time,
            from_landing_date: leadOfferData?.from_landing_date,
            landing_return_time: leadOfferData?.landing_return_time,
            to_landing_date: leadOfferData?.to_landing_date,
            airline: leadOfferData?.from_airline,
            to_airline: leadOfferData?.to_airline,
            flight_return_time: leadOfferData?.flight_return_time,
            lead_contract_service_ids: leadContractServiceIds ?? [],
            lead_contract_condition_ids: leadContractConditionIds ?? [],
          }
        : {
            ...bluePrint,
            status: res_get?.data?.lead_contract?.status,
            hotel: leadOffer?.hotel,
            airport: leadOffer?.airport,
            flight_time: leadOffer?.flight_time,
            flight_return_time: leadOffer?.flight_return_time,

            //Landing time and date
            landing_time: leadOffer?.landing_time,
            from_landing_date: leadOffer?.from_landing_date,
            landing_return_time: leadOffer?.landing_return_time,
            to_landing_date: leadOffer?.to_landing_date,

            from_date: leadOffer?.from_date,
            to_date: leadOffer?.to_date,
            airline: leadOffer?.from_airline,
            to_airline: leadOffer?.to_airline,
            lead_contract_service_ids: leadContractServiceIds ?? [],
            lead_contract_condition_ids: leadContractConditionIds ?? [],
          }
    );
  }, [res_get, leadOffer, leadOfferData]);
  const airports = res_get_all_airports?.data?.region_airports?.map(
    (airport) => airport
  );
  const hotels = res_get_all_hotels?.data?.hotels?.map((hotel) => hotel);
  const validateHotelData = () => {
    let newErrors = {};
    // if (isEmpty(leadContractState?.expired_at)) {
    //   newErrors = { ...newErrors, expired_at: "Please select the Region" };
    // }

    // if (isEmpty(leadContractState?.to_airline)) {
    //   newErrors = { ...newErrors, airline: "Please select the Airline" };
    // }
    // if (isEmpty(leadContractState?.flight_time)) {
    //   newErrors = {
    //     ...newErrors,
    //     flight_time: "Please select the Flight Time",
    //   };
    // }
    // if (isEmpty(leadContractState?.flight_return_time)) {
    //   newErrors = {
    //     ...newErrors,
    //     flight_return_time: "Please select the Flight Return Time",
    //   };
    // }
    // if (isEmpty(leadContractState?.milestone_date1)) {
    //   newErrors = {
    //     ...newErrors,
    //     milestone_date1: "Please select the Milestone Date 1",
    //   };
    // }
    // if (isEmpty(leadContractState?.milestone_date2)) {
    //   newErrors = {
    //     ...newErrors,
    //     milestone_date2: "Please select the Milestone Date 2",
    //   };
    // }
    // if (isEmpty(leadContractState?.hotel)) {
    //   newErrors = { ...newErrors, hotel: "Please select the Hotel" };
    // }
    // if (isEmpty(leadContractState?.airport)) {
    //   newErrors = { ...newErrors, airport: "Please select the Airport" };
    // }

    return newErrors;
  };
  function isEmpty(value) {
    if (value === null || value === undefined) {
      return true;
    }
    if (typeof value === "string" && value.trim() === "") {
      return true;
    }
    if (Array.isArray(value) && value.length === 0) {
      return true;
    }
    if (typeof value === "object" && Object.keys(value).length === 0) {
      return true;
    }
    return false;
  }
  function getFirstErrorMessage(errors) {
    for (const key in errors) {
      if (errors.hasOwnProperty(key)) {
        return errors[key];
      }
    }
    return "No errors found";
  }

  console.log(leadContractState);
  useEffect(() => {
    if (!leadContractState?.detail) {
      setLeadContractState((prev) => ({
        ...prev,
        detail: aboutYakSport,
      }));
    }
    if (!leadContractState?.milestones_detail) {
      setLeadContractState((prev) => ({
        ...prev,
        milestones_detail: leadContractDetail,
      }));
    }
  }, [leadContractState?.detail, aboutYakSport, leadContractDetail]);

  const handleFromLandingDateChange = (toValue) => {
    console.log("object23",leadContractState);
    const selectedDate = dayjs(toValue).format("YYYY-MM-DD");
    setSelectedLeadContract({
      ...leadContractState,
      from_landing_date: selectedDate,
    });
    console.log("object233",leadContractState);

  };
    const handleToLandingDateChange = (toValue) => {
      const selectedDate = dayjs(toValue).format("YYYY-MM-DD");
      setSelectedLeadContract({
        ...leadContractState,
        to_landing_date: selectedDate,
      });
    };
  return (
    <div>
      {openError && (
        <ErrorSnackbar
          error={getFirstErrorMessage(errors)}
          setOpenError={setOpenError}
        />
      )}
      {showBackDrop && (
        <SimpleBackdrop
          responseAdd={responseAdd}
          responseUpdate={responseUpdate}
        />
      )}
      <Snackbar
        responseAdd={
          responseAdd?.isSuccess === true ? responseAdd : responseUpdate
        }
      />

      {leadContractState && hotels ? (
        <div style={addUpdateDivStyleGlobal}>
          <Grid container spacing={3.75}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <Grid item xs={12} md={6} lg={6} style={{ marginTop: 9 }}>
                <DatePicker
                  label="Contract Expire Date "
                  defaultValue={
                    leadContractState?.expired_at
                      ? dayjs(leadContractState.expired_at)
                      : setLeadContractState((x) => ({
                          ...x,
                          expired_at: dayjs().format("YYYY-MM-DD"),
                        }))
                  }
                  inputFormat="YYYY-MM-DD"
                  onChange={handleExpiredDateChange}
                  slotProps={{ textField: { fullWidth: true, error: false } }}
                />
                {error?.expired_at && (
                  <p style={{ color: "red" }}>Please Select Date</p>
                )}
              </Grid>
            </LocalizationProvider>
            <Grid item xs={12} md={6} lg={6}>
              <Div sx={{ mb: 0.5, mt: 1 }}>
                <DZSimpleDropDown
                  placeholder={"Lead Contract Status"}
                  value={leadContractState?.status || "draft"}
                  error={errors?.status}
                  data={lead_contract_status}
                  onChange={(e) => {
                    setLeadContractState((x) => ({
                      ...x,
                      status: e.target.value,
                    }));
                  }}
                />
              </Div>
            </Grid>
            <Grid item xs={6} md={6} lg={6}>
              <DZTextValidation
                error={error?.airline || errors?.airline}
                label="From Airline"
                variant="outlined"
                value={leadContractState?.airline}
                onChange={(e) => {
                  setLeadContractState((x) => ({
                    ...x,
                    airline: e.target.value,
                  }));
                }}
                placeholder="From Airline"
              />
            </Grid>

            <Grid item xs={6} md={6} lg={6}>
              <DZTextValidation
                error={error?.to_airline || errors?.to_airline}
                label="To Airline"
                variant="outlined"
                value={leadContractState?.to_airline}
                onChange={(e) => {
                  setLeadContractState((x) => ({
                    ...x,
                    to_airline: e.target.value,
                  }));
                }}
                placeholder="Airline"
              />
            </Grid>
            <Grid item xs={12} md={12} lg={12}>
              <DZLabel title={"Flight Times"} mt="0" />
            </Grid>

            <Grid item xs={12} md={12} lg={12}>
              <DZTextFieldDisable
                label="From and To"
                variant="outlined"
                value={
                  leadContractState?.from_date +
                  " To " +
                  leadContractState?.to_date
                }
              />
            </Grid>
            {/* <Grid item xs={12} md={3} lg={3}>
              <DZTextFieldDisable
                label="From Landing date"
                variant="outlined"
                value={leadContractState?.from_landing_date}
              />
            </Grid> */}
             <LocalizationProvider dateAdapter={AdapterDayjs}>

             <Grid item xs={12} md={3} lg={3}>
                {
                  <DatePicker
                    label="From Landing Date"
                    defaultValue={dayjs(leadContractState?.from_landing_date)}
                    inputFormat="YYYY-MM-DD"
                    onChange={handleFromLandingDateChange}
                    error={
                      (leadContractState?.from_landing_date &&
                        "Please Select Date") ||
                      errors?.from_landing_date
                    }
                    slotProps={{ textField: { fullWidth: true, error: false } }}
                  />
                }
                {error?.from_landing_date && (
                  <p style={{ color: "red" }}>Please Select Date</p>
                )}
              </Grid>
            <Grid item xs={12} md={3} lg={3}>
              <Div sx={{ mb: 0.5 }}>
                <TextField
                  id="time"
                  label="From Landing Time"
                  type="time"
                  value={leadContractState?.landing_time}
                  error={
                    (error?.landing_time && "This Field is required") ||
                    errors?.landing_time
                  }
                  onChange={(e) => {
                    setLeadContractState((x) => ({
                      ...x,
                      landing_time: e.target.value,
                    }));
                  }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  inputProps={{
                    step: 300, // 5 min
                  }}
                  sx={{ width: "100%" }}
                />
              </Div>
            </Grid>

             <Grid item xs={12} md={3} lg={3}>
                            {(
                              <DatePicker
                                label="Return Landing Date"
                                defaultValue={dayjs(leadContractState?.to_landing_date)}
                                inputFormat="YYYY-MM-DD"
                                onChange={handleToLandingDateChange}
                                error={
                                  (leadContractState?.to_landing_date &&
                                    "Please Select Date") ||
                                  errors?.to_landing_date
                                }
                                slotProps={{ textField: { fullWidth: true, error: false } }}
                              />
                            )}
                            {error?.to_landing_date && (
                              <p style={{ color: "red" }}>Please Select Date</p>
                            )}
                          </Grid>
                          <Grid item xs={12} md={3} lg={3}>
              <Div sx={{ mb: 0.5 }}>
                <TextField
                  id="time"
                  label="From Landing Time"
                  type="time"
                  value={leadContractState?.landing_return_time}
                  error={
                    (error?.landing_return_time && "This Field is required") ||
                    errors?.landing_return_time
                  }
                  onChange={(e) => {
                    setLeadContractState((x) => ({
                      ...x,
                      landing_return_time: e.target.value,
                    }));
                  }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  inputProps={{
                    step: 300, // 5 min
                  }}
                  sx={{ width: "100%" }}
                />
              </Div>
            </Grid>

            </LocalizationProvider>
            {/* <Grid item xs={12} md={3} lg={3}>
              <DZTextFieldDisable
                label="From Landing Time"
                variant="outlined"
                value={leadContractState?.landing_time}
              />
            </Grid> */}
            {/* <Grid item xs={12} md={3} lg={3}>
              <DZTextFieldDisable
                label="To Landing Date"
                variant="outlined"
                value={leadContractState?.to_landing_date}
              />
            </Grid> */}
            {/* <Grid item xs={12} md={3} lg={3}>
              <DZTextFieldDisable
                label="To Landing Time"
                variant="outlined"
                value={leadContractState?.landing_return_time}
              />
            </Grid> */}
            <Grid item xs={12} md={6} lg={6}>
              <Div sx={{ mb: 0.5, mt: 1 }}>
                <TextField
                  id="time"
                  label="Flight time"
                  type="time"
                  error={errors?.flight_time}
                  value={leadContractState?.flight_time}
                  onChange={(e) => {
                    setLeadContractState((x) => ({
                      ...x,
                      flight_time: e.target.value,
                    }));
                  }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  inputProps={{
                    step: 300, // 5 min
                  }}
                  sx={{ width: "100%" }}
                />
              </Div>
            </Grid>
            <Grid item xs={12} md={6} lg={6}>
              <Div sx={{ mb: 0.5, mt: 1 }}>
                <TextField
                  id="time"
                  label="Flight Home"
                  type="time"
                  value={leadContractState?.flight_return_time}
                  onChange={(e) => {
                    setLeadContractState((x) => ({
                      ...x,
                      flight_return_time: e.target.value,
                    }));
                  }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  inputProps={{
                    step: 300, // 5 min
                  }}
                  sx={{ width: "100%" }}
                />
              </Div>
            </Grid>

            <Grid item xs={12} md={12} lg={12}>
              <DZLabel title={"Milestone Dates"} mt="0" />
            </Grid>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <Grid item xs={12} md={6} lg={6}>
                <FormControlLabel
                  label={"Disable Milestone 1"}
                  control={
                    <Checkbox
                      checked={
                        leadContractState?.is_milestone_date1 == 0
                          ? true
                          : false
                      }
                      name={"is_milestone_date1"}
                      onChange={(e) => {
                        const checked = e.target.checked;
                        setLeadContractState((pre) => ({
                          ...pre,
                          is_milestone_date1: checked ? 0 : 1,
                        }));
                      }}
                    />
                  }
                />

                <DatePicker
                  label="10% change in the number of passengers date reminder"
                  defaultValue={
                    leadContractState?.milestone_date1
                      ? dayjs(leadContractState.milestone_date1)
                      : setLeadContractState((x) => ({
                          ...x,
                          milestone_date1: dayjs()
                            .add(1, "month")
                            .format("YYYY-MM-DD"),
                        }))
                  }
                  inputFormat="YYYY-MM-DD"
                  onChange={handleMileStone1DateChange}
                  slotProps={{ textField: { fullWidth: true, error: false } }}
                />
                {error?.milestone_date1 && (
                  <p style={{ color: "red" }}>Please Select Date</p>
                )}
              </Grid>

              <Grid item xs={12} md={6} lg={6}>
                <FormControlLabel
                  label={"Disable Milestone 2"}
                  control={
                    <Checkbox
                      checked={
                        leadContractState?.is_milestone_date2 == 0
                          ? true
                          : false
                      }
                      name={"is_milestone_date2"}
                      onChange={(e) => {
                        const checked = e.target.checked;
                        setLeadContractState((pre) => ({
                          ...pre,
                          is_milestone_date2: checked ? 0 : 1,
                        }));
                      }}
                    />
                  }
                />

                <DatePicker
                  label="Passenger name list reminder"
                  defaultValue={
                    leadContractState?.milestone_date2
                      ? dayjs(leadContractState.milestone_date2)
                      : setLeadContractState((x) => ({
                          ...x,
                          milestone_date2: dayjs()
                            .add(1, "month")
                            .format("YYYY-MM-DD"),
                        }))
                  }
                  inputFormat="YYYY-MM-DD"
                  onChange={handleMileStone2DateChange}
                  slotProps={{ textField: { fullWidth: true, error: false } }}
                />
              </Grid>
            </LocalizationProvider>

            <Grid item xs={12} md={6} lg={6}>
              <DZTextFieldDisable
                label="Hotel"
                variant="outlined"
                value={leadContractState?.hotel}
              />
            </Grid>
            <Grid item xs={12} md={6} lg={6}>
              <DZTextFieldDisable
                label="Airport"
                variant="outlined"
                value={leadContractState?.airport}
              />
            </Grid>
          </Grid>

          <DZLabel title={"Lead Services"} />
          <div className="col2__box">
            {leadData?.map((lead) => (
              <Grid item xs={5} md={2} lg={2} key={lead?.id}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={leadContractState?.lead_contract_service_ids?.includes(
                        lead?.id
                      )}
                      name={lead?.title?.[selectedLanguage]}
                      onChange={(e) => {
                        const checked = e.target.checked;
                        setLeadContractState((prevState) => ({
                          ...prevState,
                          lead_contract_service_ids: checked
                            ? [
                                ...prevState?.lead_contract_service_ids,
                                lead?.id,
                              ]
                            : prevState?.lead_contract_service_ids.filter(
                                (id) => id !== lead?.id
                              ),
                        }));
                      }}
                    />
                  }
                  label={lead?.title?.[selectedLanguage]}
                />
              </Grid>
            ))}
          </div>
          <DZLabel title={"Lead Conditions"} />
          <div className="col2__box">
            {leadContractConditionData?.map((lead) => (
              <Grid item xs={5} md={2} lg={2} key={lead?.id}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={leadContractState?.lead_contract_condition_ids?.includes(
                        lead?.id
                      )}
                      name={lead?.title?.[selectedLanguage]}
                      onChange={(e) => {
                        const checked = e.target.checked;
                        setLeadContractState((prevState) => ({
                          ...prevState,
                          lead_contract_condition_ids: checked
                            ? [
                                ...prevState?.lead_contract_condition_ids,
                                lead?.id,
                              ]
                            : prevState?.lead_contract_condition_ids.filter(
                                (id) => id !== lead?.id
                              ),
                        }));
                      }}
                    />
                  }
                  label={lead?.title?.[selectedLanguage]}
                />
              </Grid>
            ))}
          </div>

          <Grid container spacing={3.75}>
            <Grid item xs={12} md={12} lg={12}>
              <DZLabel title={"Detail"} mt="0" />
            </Grid>
            <Grid item xs={12} md={12} lg={12}>
              <DzTextEditorMultilingual
                data={
                  leadContractState?.detail?.[selectedLanguage] ||
                  aboutYakSport?.[selectedLanguage]
                }
                onChange={(newContent) => {
                  setLeadContractState((x) => ({
                    ...x,
                    detail: { ...x.detail, [selectedLanguage]: newContent },
                  }));
                }}
              />
            </Grid>
            <Grid item xs={12} md={12} lg={12}>
              <DZLabel title={"Milestone Detail"} mt="0" />
            </Grid>
            <Grid item xs={12} md={12} lg={12}>
              <DzTextEditorMultilingual
                data={
                  leadContractState?.milestones_detail?.[selectedLanguage] ||
                  leadContractDetail?.[selectedLanguage]
                }
                onChange={(newContent) => {
                  setLeadContractState((x) => ({
                    ...x,
                    milestones_detail: {
                      ...x.milestones_detail,
                      [selectedLanguage]: newContent,
                    },
                  }));
                }}
              />
            </Grid>
          </Grid>
          <div style={dialogButtonsStyle}>
            <Button
              variant="outlined"
              onClick={() => {
                onClose();
                dispatch(setSelectedLeadContract(null));
              }}
            >
              Cancel
            </Button>
            {selectedLeadContract?.id ? (
              <Button
                variant="contained"
                onClick={() => {
                  addUpdateLeadContractData();

                  setShowBackDrop(true);
                  resetCount();
                }}
              >
                Update
              </Button>
            ) : (
              <Button
                variant="contained"
                onClick={() => {
                  addUpdateLeadContractData("add");
                  setShowBackDrop(true);
                  resetCount();
                  dispatch(setSelectedLeadContract(null));
                }}
              >
                Add
              </Button>
            )}
          </div>
        </div>
      ) : (
        <div>Loading....</div>
      )}
    </div>
  );
};

export default AddUpdateLeadContract;
